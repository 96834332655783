import React, { useState, useRef, useEffect } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import ReactPixel from "react-facebook-pixel"

const NewsLetter = () => {
  const [email, setEmail] = useState("")
  const [tracking, setTracking] = useState(false)
  const signupButton = useRef(null)
  const [signupButtonMsg, setSignupButtonMsg] = useState("Submit")
  // console.log('email input', email);
  const [isError, setIsError] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [inputMsg, setInputMsg] = useState("Email Address*")
  const handleEmailChange = e => {
    // console.log('e.currentTarget.value', e.currentTarget.value);
    setEmail(e.currentTarget.value)
  }

  useEffect(() => {
    if (isProcessing) setSignupButtonMsg("Processing")
    else if (!isProcessing) setSignupButtonMsg("Submit")
  }, [isProcessing])

  const handleSubmit = e => {
    e.preventDefault()
    if (!tracking) {
      // ReactPixel.track('track', 'Lead');
      ReactPixel.track("Lead")
    }
    setIsProcessing(true)
    signupButton.current.setAttribute("disabled", "disabled")
    addToMailchimp(email)
      .then(data => {
        // console.log('returned data', data.msg);
        if (data.result === "error") {
          setIsProcessing(false)
          setIsError(true)
          setEmail("")
          setInputMsg("Sorry, this email cannot be added to the list.")
          signupButton.current.removeAttribute("disabled")
        } else if (data.result === "success") {
          setIsProcessing(false)
          setEmail("")
          setInputMsg(data.msg)
        }
      })
      .catch(err => {
        console.warn("err", err)
      })
  }

  return (
    <div className="stickers-newsletter-container">
      <div className="home-newsletter-inner-container">
        {/* LETS KEEP IN TOUCH */}
        <svg
          className="newsletter-letskeepintouch"
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 85.81 19.24"
        >
          <path
            d="M62.21 116.28h2.37v6.62h3.23v2.38h-5.6zM69.62 116.28h5.59v2H72v1.46h2.85v2H72v1.66h3.23v1.93h-5.61zM78.93 118.62h-2.34v-2.35h7.09v2.35h-2.39v6.66h-2.36zM86 118.18a.94.94 0 01-.94-.93.95.95 0 111.89 0 2.5 2.5 0 01-1 1.91zM88.05 122.53h2.4c0 .39.35.7.87.7a.76.76 0 00.85-.7.78.78 0 00-.87-.71 3.06 3.06 0 01-3.24-2.77 3 3 0 013.26-2.77 3 3 0 013.25 2.72h-2.38a.8.8 0 00-.87-.69c-.51 0-.86.27-.86.69s.35.71.86.71a3 3 0 013.25 2.77 3 3 0 01-3.21 2.76c-1.97.04-3.31-1.24-3.31-2.71zM99.2 116.28h2.36v3.43l3.45-3.43h3.31l-4.53 4.52 4.46 4.48h-3.38l-2.75-2.77-.56.57v2.2H99.2zM109.47 116.28h5.59v2h-3.23v1.46h2.86v2h-2.86v1.66h3.23v1.93h-5.59zM116.86 116.28h5.59v2h-3.23v1.46h2.86v2h-2.86v1.66h3.23v1.93h-5.59zM124.25 116.28H128a3.31 3.31 0 01.05 6.61h-1.48v2.39h-2.36zm3.83 4.57a1.27 1.27 0 001.29-1.28 1.29 1.29 0 00-1.29-1.3h-1.47v2.58zM135.74 116.28h2.36v9h-2.36zM140 116.28h1l4.26 4.25v-4.25h2.36v9h-1l-4.24-4.28v4.26H140zM64.13 128.85h-2.34v-2.35h7.09v2.35h-2.39v6.66h-2.36zM74.57 135.51a4.5 4.5 0 114.5-4.49 4.48 4.48 0 01-4.5 4.49zm0-6.65a2.15 2.15 0 102.15 2.14 2.14 2.14 0 00-2.15-2.14zM80.57 132v-5.45H83v5.41a1.18 1.18 0 102.35 0v-5.41h2.37V132a3.55 3.55 0 11-7.1 0zM89.18 131a4.49 4.49 0 017.66-3.19l-1.65 1.67a2.11 2.11 0 00-1.52-.59 2.11 2.11 0 101.52 3.62l1.67 1.66a4.58 4.58 0 01-3.19 1.33 4.5 4.5 0 01-4.49-4.5zM98.43 126.51h2.36v3.21h2.86v-3.21H106v9h-2.36v-3.43h-2.86v3.43h-2.35z"
            transform="translate(-61.79 -116.27)"
          />
        </svg>

        {/* SUBSCRIBE  */}
        <form
          onSubmit={handleSubmit}
          className="newsletter-nospam"
          method="POST"
          action="/thankyou"
          netlify-honeypot="bot-field"
          data-netlify="true"
          name="registration"
        >
          <input type="hidden" name="form-name" value="registration" />
          {/* <div className="newsletter-nospam"> */}
          <div className="nospam-title">No Junk, Just Journals</div>
          <div className="newsletter-input-container">
            {/* <label> */}
            {/* <input className="newsletter-input" type="text" name="email" /> */}
            <input
              type="email"
              name="email"
              value={email}
              placeholder={`${inputMsg}`}
              onChange={handleEmailChange}
              className="newsletter-input"
            />
            {/* </label> */}
            <span>
              <button ref={signupButton} className="newsletter-button">
                {signupButtonMsg}
              </button>
            </span>
          </div>
          {/* </div> */}
        </form>
      </div>
    </div>
  )
}

export default NewsLetter
